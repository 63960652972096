@import "../../../styles/colors";

nav {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a.logo {
    padding: 0;
    background-color: transparent;
  }
  .menu {
    box-shadow: 0 0 0 2px #5C647F;
  }

  .menu:hover {
    background-color: $hover-color;
  }
}
