@import "../../../styles/colors";

header {
  height: 40vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin: 3rem 0;
  background-image: url('../../../img/header/smallheader.jpg');
  background-size: cover;
  background-position: center;
  .shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $main-color;
    border-radius: 12px;
    opacity: 40%;
    z-index: 1;
  }

  .header-text {
    margin: 3rem;
    z-index: 20;

    .date {
      padding: 2rem 0 0;
      font-size: 1em;
      line-height: 3rem;
      text-align: center;
      color: $light-color;
    }
  }
}

@media (min-width: 768px) {
  header {
    background-image: url('../../../img/header/bigheader.jpg');
  }
}

