@import "styles/colors";

body {
  background-color: $light-color;
  letter-spacing: .2rem;

  .wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    min-width: 320px;

    h1 {
      color: $light-color;
      font-weight: bold;
      text-align: center;
      padding: 1rem;
    }

    h2 {
      padding: 2rem;
      color: $light-color;
    }

    h3 {
      padding: 2rem;
      color: $main-color;
      text-align: center;
      margin: 0 auto;
      max-width: 500px;
    }

    h4 {
      text-align: center;
      color: $main-color;
      padding: 1rem;
      line-height: 2.5rem;
    }

    .table {
      background-color: $main-color;
      padding: 3rem 0;
      border-radius: 12px;
      min-width: 300px;
      margin: 3rem auto;

      table {
        margin: 1rem auto;
        padding: .5rem;
        background-color: $light-color;
        border-radius: 12px;

        thead {
          padding: 1rem;

          tr {
            background-color: $main-color;

            th {
              color: $light-color;
              font-size: 2rem;
              padding: 2rem;
            }
          }
        }

        tbody {
          td {
            padding: 1.5rem;
            text-align: center;
            color: $main-color;
            border: 1px $main-color solid;

          }

          tr {
            background-color: $light-color;
          }
        }
      }

      .summary {
        color: $light-color;
        padding: 2rem;
        line-height: 2.5rem;
        text-align: center;
      }

      .load {
        text-align: center;
      }
    }


    .form-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $main-color;
      border-radius: 12px;
      padding: 4rem 0;
      margin: 3rem 0;

      .btn-add {
        margin: 1rem;
      }
      .result {
        color: $hover-color;
        padding: 2rem;
      }

      .form {
        display: flex;
        flex-direction: column;
        background-color: $light-color;
        padding: 1rem;
        margin: 1rem;
        border-radius: 12px;
        width: 300px;

        label {
          color: $main-color;
          padding: 1rem;
        }

        input, select {
          padding: 1rem 2rem;
          margin-bottom: .5rem;
          border-radius: 12px;
        }

        .btn {
          margin: 2rem 0;
          text-align: center;
          box-shadow: 0 0 0 2px #5C647F;
        }
      }
    }
  }
}
