@import "../../../styles/colors";

.verify-view {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: $main-color;
  border-radius: 12px;
  color: $light-color;
  padding: 3rem 1rem;
  margin: 3rem 0;

  .verify {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 1rem;
    padding: 1rem;
    background-color: $light-color;
    width: 90%;
    max-width: 700px;
    border-radius: 12px;
    p {
      padding: 2rem 1rem;
      color: $main-color;
      text-align: center;
    }

    img {
      margin: 1rem;
    }
  }
}
