.error {
  text-align: center;
  border: 1px solid #f50517;
  padding: 2rem;
  color: #f50517;
}

.success {
  text-align: center;
  border: 1px solid #20c70b;
  padding: 2rem;
  color: #20c70b;
}
